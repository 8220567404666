/* eslint-disable react/prop-types */
import Elements from "./elements/component";
import { useState, useEffect } from "react";
import { isClientSide } from "@constants";
import { TCompanyLogos } from "@@types/wordPress";

type CompaniesBlockProps = {
  numberLogos: number;
  data: TCompanyLogos[];
  clickCreateAccount: boolean;
};

const CompaniesBlock: React.FC<CompaniesBlockProps> = ({ data, numberLogos, clickCreateAccount }) => {
  const [numCompanies, setNumCompanies] = useState(numberLogos);

  useEffect(() => {
    if (isClientSide) {
      const handleResize = () => {
        if (window.innerWidth <= 600) {
          setNumCompanies(12);
        } else {
          setNumCompanies(numCompanies);
        }
      };

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  return <Elements data={data} clickCreateAccount={clickCreateAccount} />;
};

export default CompaniesBlock;
