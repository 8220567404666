/* eslint-disable react/prop-types */
import classNames from "classnames";
import React from "react";
import style from "./homeV2.module.scss";

type Props = {
  children: React.ReactNode;
  type?: "Flex" | "Block";
  bg?: "gray" | "custom";
  customBgColor?: string;
};

const generalClasses = (type: string | undefined) => {
  const classes = {
    [style.sectionContainerFlex]: type === "Flex",
    [style.sectionContainerBlock]: type === "Block",
    [style.sectionContainerFlex]: type == undefined
  };

  return classNames(style.sectionContainer, classes);
};

const GrayContainer = ({ children, type }: Props) => {
  const validationType = classNames(style.sectionContainerBgGray, generalClasses(type));
  return <div className={validationType}>{children}</div>;
};

const CustomContainer = ({ children, type, customBgColor }: Props) => {
  const validationType = classNames(generalClasses(type));
  const styles = {
    backgroundColor: `${customBgColor}`
  };
  return (
    <div className={validationType} style={styles}>
      {children}
    </div>
  );
};

const SectionContainer = ({ bg, children, type, customBgColor }: Props) => {
  switch (bg) {
    case "gray":
      return <GrayContainer type={type}>{children}</GrayContainer>;
    case "custom":
      return (
        <CustomContainer type={type} customBgColor={customBgColor}>
          {children}
        </CustomContainer>
      );
    default:
      return <GrayContainer type={type}>{children}</GrayContainer>;
  }
};

export default SectionContainer;
