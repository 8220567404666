import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";
import { TEndpoint } from "../@types/api.core";
import Api from "../core/api.core";

const { publicUserBackUrl } = getConfig().publicRuntimeConfig;

export class UserBackRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    scripts: {
      userBack: "/widget/v1.js"
    }
  };
  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: publicUserBackUrl }, baseOptions));
  }
  async getUserBackScript() {
    return Promise.resolve(`${publicUserBackUrl}${this.endpoints.scripts.userBack}`);
  }
}

export default new UserBackRepository();
