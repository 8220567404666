/* eslint-disable react/prop-types */
import Link from "next/link";
import style from "./businessCorporate.module.scss";

type BusinessAndCorporateButtonProps = {
  rest: dataProps;
};

type dataProps = {
  small_title: string;
  text: string;
  url: string;
};

const BusinessAndCorporateButton: React.FC<BusinessAndCorporateButtonProps> = ({ rest }) => {
  return (
    <Link href={rest.url} className={style.buttonBusinessCorporateButton}>
      <p>
        <small>{rest.small_title}</small>
        <span>{rest.text}</span>
      </p>
      <i className="las la-angle-right"></i>
    </Link>
  );
};

export default BusinessAndCorporateButton;
