import { tail } from "ramda";
import { fullBasePath } from "@constants/environment.constant";
import { NextRouter } from "next/router";
import { removeCharacter } from "@utils/url";

const breadcrumbSchema = (routes: NextRouter) => {
  const fullUrl = new URL(fullBasePath + routes.asPath);

  const { origin } = fullUrl;

  const pathName = tail(removeCharacter(fullUrl.pathname, "/").split("/"));

  const urlToBreadcrumb = [origin, ...pathName];

  let url = "";
  let finalUrl = "";

  const itemListElement = urlToBreadcrumb.map((path, index) => {
    url += path + "/";
    if (url.lastIndexOf("/") !== -1) {
      finalUrl = url.substring(0, url.lastIndexOf("/"));
    }
    const name = path.charAt(0).toUpperCase() + path.slice(1).replaceAll("-", " ");
    return {
      "@type": "ListItem",
      position: index + 1,
      name: index === 0 ? "Inicio" : name,
      item: finalUrl
    };
  });

  return {
    __html: JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: itemListElement
    })
  };
};

export default breadcrumbSchema;
