import React, { useEffect } from "react";
import style from "./bannerBrowser.module.scss";
import SearchEngine from "./searchEngine/component";
import parse from "html-react-parser";
import { TWpGlobal } from "@@types/wordPress";
import Image from "next/image";
import { TFunction, withTranslation } from "next-i18next";
import ButtonFilter from "./buttonFilter/component";
import useScreenSizeListener from "@hooks/screenSizeListener/useScreenSizeListener";
import classNames from "classnames";

type BlockBannerBrowserProps = {
  data: TWpGlobal;
  t: TFunction;
};

const BlockBannerBrowser: React.FC<BlockBannerBrowserProps> = ({ data, t }) => {
  const buttonsBanner = data.acf.buttons_banner;
  const dataBanner = data.acf.title_and_subtitle_banner;
  const smallScreen = useScreenSizeListener(1050);
  const christmasAnimation = data.acf.christmas_animation;
  const bannerBrowser = classNames(style.containerBannerBrowser, {
    snowContainer: christmasAnimation,
    [style.snowContainer]: christmasAnimation
  });

  const useChristmasAnimationEffect = (numb: number) => {
    useEffect(() => {
      if (christmasAnimation) {
        const getRandomValue = (max: number, min = 1) => {
          return Math.floor(Math.random() * max) + min;
        };

        const createSnow = (density: number) => {
          for (let i = 0; i < density; i++) {
            const snowElement = document.createElement("span");
            const randomHorizontalPosition = `${getRandomValue(100)}%`;
            const randomFallDelay = `${getRandomValue(50)}s`;
            const randomFallDuration = `${getRandomValue(10, 3)}s`;
            const randomSize = `${getRandomValue(8, 3)}px`;
            const randomOpacity = Math.random().toFixed(2);
            snowElement.classList.add(style.snow);
            snowElement.style.opacity = randomOpacity;
            snowElement.style.right = randomHorizontalPosition;
            snowElement.style.height = randomSize;
            snowElement.style.width = randomSize;
            snowElement.style.animationDuration = randomFallDuration;
            snowElement.style.animationDelay = randomFallDelay;
            document.querySelector(".snowContainer")?.appendChild(snowElement);
          }
        };
        createSnow(numb);
      }
    }, [numb]);
  };

  useChristmasAnimationEffect(300);

  return (
    <section className={bannerBrowser}>
      {christmasAnimation}
      <div className={style.containerInfo}>
        <div>
          <div className={style.infoBanner}>
            <h1 className={style.bannerTitle} style={{ fontSize: `${dataBanner.banner_title_size}%` }}>
              {parse(dataBanner.banner_title)}
            </h1>
            <p className={style.bannerSubTitle} style={{ fontSize: `${dataBanner.banner_subtitle_size}%` }}>
              {parse(dataBanner.banner_subtitle)}
            </p>
          </div>
          <SearchEngine />
          <div className={style.containerButtonsBanner}>
            <ButtonFilter data={buttonsBanner.first_button} />
            <ButtonFilter data={buttonsBanner.second_button} />
            <ButtonFilter data={buttonsBanner.third_button} />
            <ButtonFilter data={buttonsBanner.fourth_button} />
          </div>
        </div>
        {!smallScreen ? (
          <Image
            src={dataBanner.banner_figure}
            alt={t<string>("genericTexts:altTexts.corporateMagneto")}
            width={360}
            height={436}
            className={style.bannerFigure}
            priority
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default withTranslation()(BlockBannerBrowser);
