import { fullBasePath } from "@constants";
import { useEffect, useState } from "react";

export const useMetaDataProcessor = (metaData: string, asPath: string) => {
  const data = (data: string, path: string) => {
    let titleContent = "";
    const titleRegex = /<title>(.*?)<\/title>/;

    const updatedMetaDataCanonical = data.replace(
      /<link rel="canonical" href="(.+?)" \/>/,
      `<link rel="canonical" href="${fullBasePath}${path === "/" ? "" : path}" />`
    );

    const match = data.match(titleRegex);
    if (match) titleContent = match[1];

    const metaTitle = `<meta name="title" content="${titleContent}" />`;
    const initialData = metaTitle + updatedMetaDataCanonical;

    return { initialData };
  };

  const { initialData } = data(metaData, asPath);

  const [metaDataCanonical, setMetaDataCanonical] = useState(initialData);

  useEffect(() => {
    const dynamicConical = data(initialData, asPath);
    setMetaDataCanonical(dynamicConical.initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataCanonical, asPath]);

  return metaDataCanonical;
};
