/* eslint-disable react/prop-types */
import CompaniesBlock from "@components/validationCompanyLogos/companiesBlock/component";
import dataJsonValidate from "@utils/dataJsonValidate/dataJsonValidate";
import { Fragment } from "react";
import parse from "html-react-parser";
import style from "./validationCompanyLogos.module.scss";

type TDataLogos = {
  title: string;
  text: string;
  companies: string;
  restData: string;
  onclick_create_account: boolean;
};

type ValidationCompanyLogosProps = {
  dataLogos: TDataLogos;
  titleTag: "h1" | "h2";
};

const ValidationCompanyLogos: React.FC<ValidationCompanyLogosProps> = ({ dataLogos, titleTag }) => {
  const { dataJson: logos, dataValidate: logosValidate } = dataJsonValidate({
    restInfo: dataLogos,
    restData: dataLogos.companies
  });

  const dynamicTag = (tag: string) => {
    const contentTag = parse(dataLogos.title);
    return <Fragment>{tag === "h1" ? <h1>{contentTag}</h1> : <h2>{contentTag}</h2>}</Fragment>;
  };

  return (
    <Fragment>
      <div className={style.containerBlockCompany}>
        <div className={style.blockCompanyInfo}>
          {dataLogos.title && dynamicTag(titleTag)}
          {dataLogos.text && <p>{dataLogos.text}</p>}
        </div>
        {logosValidate && (
          <CompaniesBlock
            data={logos}
            numberLogos={logos.length}
            clickCreateAccount={dataLogos.onclick_create_account}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ValidationCompanyLogos;
