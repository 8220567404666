import { TWpGlobal } from "@@types/wordPress";
import { imgDefault } from "@constants";

const articleSchema = (dataBlog: TWpGlobal[]) => {
  const urlPost = dataBlog[0].slug;
  const urlPostImage = dataBlog[0].uagb_featured_image_src.full[0];
  const excerpt = dataBlog[0].content.rendered.match(/<p>(.*?)<\/p>/s);
  const excerptContent = excerpt ? excerpt[1] : dataBlog[0].content.rendered;
  const postImage = urlPostImage == undefined ? imgDefault : urlPostImage;

  let textWithoutLink = "";

  if (excerptContent.indexOf("</a>")) {
    textWithoutLink = excerptContent.replace(/<a[^>]*>(.*?)<\/a>/g, "$1");
  }

  return {
    __html: JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://www.magneto365.com/es/blog/${urlPost}`
      },
      headline: dataBlog[0].title.rendered,
      description: textWithoutLink,
      image: postImage,
      author: {
        "@type": "Person",
        name: dataBlog[0].uagb_author_info.display_name
        // url: dataBlog[0].uagb_author_info.author_link
      },
      publisher: {
        "@type": "Organization",
        name: "Magneto Global sas",
        logo: {
          "@type": "ImageObject",
          url: "https://static.magneto365.com/lib/assets/cef805bdf449b93b.svg"
        }
      },
      datePublished: `${dataBlog[0].date}-05:00`,
      dateModified: `${dataBlog[0].modified}-05:00`
    })
  };
};

export default articleSchema;
