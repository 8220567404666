/* eslint-disable react/prop-types */
import { TWpGlobal } from "@@types/wordPress";
import { useMetaDataProcessor } from "@hooks/useMetaDataProcessor";
import articleSchema from "@jsonLdScripts/articleSchema";
import breadcrumbSchema from "@jsonLdScripts/breadcrumbSchema";
import organizationShcema from "@jsonLdScripts/organizationSchema";
import webSiteSchema from "@jsonLdScripts/webSiteSchema";
import parse from "html-react-parser";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement } from "react";

type seoProps = {
  metaData: string;
  dataBlog?: TWpGlobal[];
};

const MetaInfo: React.FC<seoProps> = ({ metaData, dataBlog }): ReactElement => {
  const nextRouter = useRouter();
  const { pathname, asPath } = nextRouter;
  const pathNoUtm = asPath.split("?")[0];

  const metaDataCanonical = useMetaDataProcessor(metaData, pathNoUtm);

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {parse(`${metaDataCanonical}`) as ReactElement}
      {pathname === "/" && (
        <>
          <script type="application/ld+json" dangerouslySetInnerHTML={organizationShcema()} key="organization-jsonld" />
          <script type="application/ld+json" dangerouslySetInnerHTML={webSiteSchema()} key="webSite-jsonld" />
        </>
      )}
      {(pathname.includes("/blog/") || pathname.includes("/soluciones/blog-empresas/")) && (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={articleSchema(dataBlog ?? [])}
            key="articles-jsonld"
          />
        </>
      )}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={breadcrumbSchema(nextRouter)}
        key="landing-breadcrumb-jsonld"
      />
    </Head>
  );
};

export default MetaInfo;
