/* eslint-disable react/prop-types */
import Image from "next/image";
import Link from "next/link";
import style from "./bannerPost.module.scss";
import parse from "html-react-parser";
import { TWpGlobal } from "@@types/wordPress";
import { useRouter } from "next/router";
import { Fragment } from "react";

type BannerPostProps = {
  data: TWpGlobal[];
  quantity?: number;
};

const BannerPost: React.FC<BannerPostProps> = ({ data, quantity }) => {
  const filter = data.slice(0, quantity);
  const { pathname } = useRouter();
  return (
    <Fragment>
      {filter.map((b, index) => (
        <article key={index} className={style.banner}>
          <Image
            src={b.uagb_featured_image_src.full[0] as string}
            width={1400}
            height={400}
            alt={b.title.rendered}
            priority
          />
          <div className={style.info}>
            <h2>{parse(b.title.rendered)}</h2>
            <div>{parse(b.excerpt.rendered.slice(0, 100) + "...")}</div>
            <Link href={`${pathname}/[slug]`} as={`${pathname}/${b.slug}`}>
              Ver más
            </Link>
          </div>
        </article>
      ))}
    </Fragment>
  );
};

export default BannerPost;
