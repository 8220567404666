/* eslint-disable react/prop-types */
import { jobsHostBase } from "@constants";
import useOpenWidgetCreateAccount from "@hooks/magnetoUi/useOpenWidgetToApply";
import { TFunction, withTranslation } from "next-i18next";
import Link from "next/link";
import style from "./companies.module.scss";
import CompaniesImg from "./logo/component";

type Data = {
  url: string;
  img: string;
  name: string;
};

type CompaniesLogosProps = {
  data: Data[];
  t: TFunction;
  clickCreateAccount: boolean;
};

const CompaniesLogos: React.FC<CompaniesLogosProps> = ({ data, clickCreateAccount, t }) => {
  const { openCreateAccount } = useOpenWidgetCreateAccount();
  const altText = t<string>("genericTexts:altTexts.signUp&FindJob");

  return (
    <ul className={style.contentCompanies}>
      {data.map((d, index) => (
        <li key={index}>
          {clickCreateAccount ? (
            <button onClick={openCreateAccount} className={style.logoCreateAccount}>
              <CompaniesImg data={d} alt={altText} />
            </button>
          ) : d.url ? (
            <Link href={`${jobsHostBase}${d.url}`}>
              <CompaniesImg data={d} alt={altText} />
            </Link>
          ) : (
            <CompaniesImg data={d} alt={altText} />
          )}
        </li>
      ))}
    </ul>
  );
};

export default withTranslation()(CompaniesLogos);
