/* eslint-disable react/prop-types */
import useScreenSizeListener from "@hooks/screenSizeListener/useScreenSizeListener";
import { FREQUENT_LINKS } from "@schemas/frequentLinks";
import { TFunction, withTranslation } from "next-i18next";
import Link from "next/link";
import { FC, useState } from "react";
import style from "./frequentLinks.module.scss";

type Props = {
  t: TFunction;
};

const FrequentLinks: FC<Props> = ({ t }) => {
  const links = FREQUENT_LINKS.links;
  const [numberLinks, setNumberLinks] = useState(false);
  const isSmallSize = useScreenSizeListener(600);

  const totalLinks = numberLinks ? links.length : isSmallSize ? Math.trunc(links.length / 3) : links.length;

  const toggleView = () => {
    setNumberLinks(!numberLinks);
  };

  return (
    <section className={style.frequentLinks}>
      <h2>{t<string>("footer:internalLinking")}</h2>
      <ul>
        {FREQUENT_LINKS.links.slice(0, totalLinks).map((f, index) => (
          <li key={index}>
            <Link href={f.url} passHref>
              {f.tag}
              <i className="las la-angle-right"></i>
            </Link>
          </li>
        ))}
      </ul>
      {isSmallSize && (
        <button onClick={toggleView}>
          {t<string>("footer:showFullList", {
            action: numberLinks ? t<string>("footer:showLess") : t<string>("footer:showMore")
          })}
        </button>
      )}
    </section>
  );
};

export default withTranslation()(FrequentLinks);
