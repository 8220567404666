import { useState } from "react";
import { jobsHostBase } from "@constants";

const useJobSearchInput = (jobRoute: string) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const jobs = jobsHostBase + jobRoute;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const onlyLetters = /^[A-Za-z0-9áéíóúüñÁÉÍÓÚÜÑ ]+$/;
    if (onlyLetters.test(value) || value === "") {
      setInputValue(value);
      setIsEmpty(value.trim() === "");
    }
  };

  const handleClearInput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setInputValue("");
    setIsEmpty(true);
  };

  const paramSearch = () => {
    const trim = inputValue.trim();
    const param = trim.replaceAll(" ", "+");
    const urlFilter = jobs + "?q=" + param;
    window.location.replace(urlFilter);
  };

  const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isEmpty) {
      paramSearch();
    } else {
      window.location.replace(jobs);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "13") {
      if (!isEmpty) {
        paramSearch();
      } else {
        e.preventDefault();
      }
    }
  };

  return {
    inputValue,
    isEmpty,
    handleInputChange,
    handleClearInput,
    handleSearch,
    handleKeyDown
  };
};

export default useJobSearchInput;
