const webSiteSchema = () => {
  return {
    __html: JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "Magneto empleos",
      url: "https://www.magneto365.com/es",
      potentialAction: {
        "@type": "SearchAction",
        target: "https://www.magneto365.com/co/empleos?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    })
  };
};

export default webSiteSchema;
