type TLinks = {
  tag: string;
  url: string;
};

type TFrequentLinks = {
  title: string;
  links: TLinks[];
};

const urlBase = "https://www.magneto365.com/co";

export const FREQUENT_LINKS: TFrequentLinks = {
  title: "footer:links.knowUs",
  links: [
    {
      tag: "Empleos en Medellín ",
      url: `${urlBase}/trabajos/ofertas-empleo-en-medellin`
    },
    {
      tag: "Empleo en Bogotá",
      url: `${urlBase}/trabajos/ofertas-empleo-en-bogota`
    },
    {
      tag: "Empleo en Barranquilla",
      url: `${urlBase}/trabajos/ofertas-empleo-en-barranquilla`
    },
    {
      tag: "Empleo en Cartagena",
      url: `${urlBase}/trabajos/ofertas-empleo-en-cartagena`
    },
    {
      tag: "Empleo en Cali",
      url: `${urlBase}/trabajos/ofertas-empleo-en-cali`
    },
    {
      tag: "Empleo en Cúcuta",
      url: `${urlBase}/trabajos/ofertas-empleo-en-cucuta`
    },
    {
      tag: "Empleo en Pereira",
      url: `${urlBase}/trabajos/ofertas-empleo-en-pereira`
    },
    {
      tag: "Empleo en Bucaramanga",
      url: `${urlBase}/trabajos/ofertas-empleo-en-bucaramanga`
    },
    {
      tag: "Empleos en Santa Marta",
      url: `${urlBase}/trabajos/ofertas-empleo-en-santa-marta`
    },
    {
      tag: "Empleos en Valledupar",
      url: `${urlBase}/trabajos/ofertas-empleo-en-valledupar`
    },
    {
      tag: "Empleos en Manizales",
      url: `${urlBase}/trabajos/ofertas-empleo-en-manizales`
    },
    {
      tag: "Empleos en Pasto",
      url: `${urlBase}/trabajos/ofertas-empleo-en-pasto`
    },
    {
      tag: "Empleos en Palmira",
      url: `${urlBase}/trabajos/ofertas-empleo-en-palmira`
    },
    {
      tag: "Administrativo auxiliar",
      url: `${urlBase}/trabajos/ofertas-empleo-de-auxiliar-administrativo`
    },
    {
      tag: "SENA empleos",
      url: `${urlBase}/trabajos/empleo-sena`
    },
    {
      tag: "Trabajos desde casa",
      url: `${urlBase}/trabajos/ofertas-trabajos-desde-casa`
    },
    {
      tag: "Servicio de empleo",
      url: `${urlBase}/trabajos/servicio-de-empleo`
    },
    {
      tag: "Bolsa de empleo",
      url: `${urlBase}/trabajos/bolsa-de-empleo`
    },
    {
      tag: "Empleos sin experiencia",
      url: `${urlBase}/trabajos/ofertas-empleo-sin-experiencia`
    },
    {
      tag: "Trabajo sin experiencia Bogotá",
      url: `${urlBase}/trabajos/empleo-sin-experiencia-bogota`
    },
    {
      tag: "Trabajo en remoto",
      url: `${urlBase}/trabajos/ofertas-empleo-trabajo-remoto`
    },
    {
      tag: "Ofertas de empleo",
      url: `${urlBase}/empleos`
    },
    {
      tag: "Trabajo sin experiencia Cali",
      url: `${urlBase}/trabajos/empleo-sin-experiencia-cali`
    },
    {
      tag: "Trabajos fines de semana",
      url: `${urlBase}/trabajos/trabajo-fines-de-semana`
    },
    {
      tag: "Trabajo sin experiencia Barranquilla",
      url: `${urlBase}/trabajos/trabajo-sin-experiencia-barranquilla`
    },
    {
      tag: "Contrato término indefinido",
      url: `${urlBase}/trabajos/ofertas-empleo-contrato-termino-indefinido`
    },
    {
      tag: "Servicio al cliente",
      url: `${urlBase}/trabajos/vacantes-servicio-al-cliente`
    }
  ]
};
