/* eslint-disable react/prop-types */
import { Fragment } from "react";
import Slider from "@components/slider/component";
import SlideHome from "@components/slider/slideHome/wrapper";
import { TWpGlobal } from "@@types/wordPress";
import style from "./blockSlider.module.scss";
import SlideVideos from "@components/slider/slideVideos/component";
import Image from "next/image";
import { multimediaDomain } from "@constants";

type BlockSliderProps = {
  dataSlider?: TWpGlobal[];
  type?: "sliderInfo" | "sliderVideos";
  videos?: video[];
  centerMode?: boolean;
};

type video = {
  name: string;
  logo: string;
  id: string;
};

const contentSlideHome = (dataSlider: TWpGlobal[]) => {
  return dataSlider.map((slide, index) => (
    <Fragment key={index}>
      <SlideHome data={slide} />
    </Fragment>
  ));
};

const contentSlideVideos = (videos: video[]) => {
  return videos.map((video, index) => (
    <div className={style.containerSlideVideo} key={index}>
      <Image src={multimediaDomain + video.logo} alt={video.name} width={100} height={50} />
      <SlideVideos video={video.id} />
    </div>
  ));
};

const BlockSlider: React.FC<BlockSliderProps> = ({ dataSlider, type, videos, centerMode }) => {
  return (
    <Slider
      customClassArrow={style.arrowSliderHome}
      customClassDots={`${style.dots} dotsHome`}
      slidesToShow={type == "sliderVideos" ? 3 : 1}
      slidesToScroll={1}
      centerMode={centerMode ? true : false}
    >
      {type == "sliderInfo"
        ? dataSlider && contentSlideHome(dataSlider)
        : type == "sliderVideos"
        ? videos && contentSlideVideos(videos)
        : dataSlider && contentSlideHome(dataSlider)}
    </Slider>
  );
};

export default BlockSlider;
