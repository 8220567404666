import { useEffect, useState } from "react";

const useScreenSizeListener = (size: number) => {
  const [isScreenSmall, setIsScreenSmall] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth <= size);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return isScreenSmall;
};

export default useScreenSizeListener;
