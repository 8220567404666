/* eslint-disable react/prop-types */
import { logoB2b } from "@constants";
import parse from "html-react-parser";
import Image from "next/image";
import SectionContainer from "../sectionContainer";
import style from "./businessCorporate.module.scss";
import BusinessAndCorporateButton from "./button";

type BusinessAndCorporateProps = {
  rest: dataProps;
};

type dataProps = {
  background: string;
  text_color: string;
  small_text: string;
  title: string;
  first_button: buttonProps;
  second_button: buttonProps;
  third_button: buttonProps;
};

type buttonProps = {
  small_title: string;
  text: string;
  url: string;
};

const BusinessAndCorporate: React.FC<BusinessAndCorporateProps> = ({ rest }) => {
  return (
    <SectionContainer bg="custom" customBgColor={rest.background}>
      <div>
        <Image src={logoB2b} alt={rest.small_text} width={180} height={80} className={style.logoMagnetoB2b} />
        <p className={style.infoText}>
          <small>{parse(rest.small_text)}</small>
        </p>
      </div>
      <h2 className={style.infoText}>{parse(rest.title)}</h2>
      <div className={style.containerButtons}>
        <BusinessAndCorporateButton rest={rest.first_button} />
        <BusinessAndCorporateButton rest={rest.second_button} />
        <BusinessAndCorporateButton rest={rest.third_button} />
      </div>
    </SectionContainer>
  );
};

export default BusinessAndCorporate;
