import Image from "next/image";
import Link from "next/link";
import React from "react";
import SectionContainer from "../sectionContainer";
import style from "./advertising.module.scss";

type Props = {
  rest: Advertising;
};

type Advertising = {
  visibility: boolean;
  title: string;
  text: string;
  banner_cua: Banner;
  banner_iep: Banner;
};

type Banner = {
  link: string;
  img: string;
  width: number;
  height: number;
  alt: string;
};

const BlockAdvertising: React.FC<Props> = ({ rest }) => {
  return (
    <>
      {rest.visibility && (
        <SectionContainer>
          <h2>{rest.title}</h2>
          <p className="text-center">{rest.text}</p>
          <div className={style.bannersAdvertising}>
            <Link href={rest.banner_cua.link} target="_blank" id="banner_cua">
              <Image
                src={rest.banner_cua.img}
                width={rest.banner_cua.width}
                height={rest.banner_cua.height}
                alt={rest.banner_cua.alt}
              />
            </Link>
            <Link href={rest.banner_iep.link} target="_blank" id="banner_iep">
              <Image
                src={rest.banner_iep.img}
                width={rest.banner_iep.width}
                height={rest.banner_iep.height}
                alt={rest.banner_iep.alt}
              />
            </Link>
          </div>
        </SectionContainer>
      )}
    </>
  );
};

export default BlockAdvertising;
