/* eslint-disable react/prop-types */
import style from "./searchEngine.module.scss";
import { TFunction, withTranslation } from "next-i18next";
import Image from "next/image";
import useJobSearchInput from "@hooks/jobSearchInput/useJobSearchInput";
import useScreenSizeListener from "@hooks/screenSizeListener/useScreenSizeListener";

type SearchEngineProps = {
  t: TFunction;
};

const SearchEngine: React.FC<SearchEngineProps> = ({ t }) => {
  const iconSearch = "https://static.magneto365.com/lib/assets/37c68bee58e241c3.svg";
  const inputPlaceholder = t<string>("header:placeholder.searchbar");
  const inputPlaceholderMobile = t<string>("header:placeholder.searchBarMobile");
  const isMobile = useScreenSizeListener(600);
  const messageValidation = isMobile ? inputPlaceholderMobile : inputPlaceholder;
  const { inputValue, isEmpty, handleInputChange, handleClearInput, handleSearch, handleKeyDown } = useJobSearchInput(
    t<string>("routes:jobs")
  );

  return (
    <form className={style.formSearchJob}>
      <div className={style.inputContainer}>
        <Image src={iconSearch} alt={messageValidation} width={25} height={25} />
        <div>
          <input
            type="text"
            autoComplete="off"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            id="search"
            required
          />
          <label htmlFor="search">{messageValidation}</label>
        </div>
        {!isEmpty && (
          <div className={style.deleteSearch}>
            <button onClick={handleClearInput}>
              <i className="las la-times"></i>
            </button>
          </div>
        )}
      </div>
      <button onClick={handleSearch} className={style.btnSearch}>
        {t<string>("filters:filtersSideBar.search")}
      </button>
    </form>
  );
};

export default withTranslation()(SearchEngine);
