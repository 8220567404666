const organizationShcema = () => {
  return {
    __html: JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Magneto Global",
      alternateName: "Magneto Empleos",
      url: "https://www.magneto365.com/es",
      logo: "https://static.magneto365.com/lib/assets/cef805bdf449b93b.svg",
      description:
        "Magneto es una plataforma 100% digital en la cual las empresas, negocios y emprendimientos pueden publicar ofertas de empleo, a la vez que las personas que buscan empleo pueden revisar sobre miles de vacantes publicadas todos los días, a las cuales pueden postularse registrándose en el sitio web.",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Carrera 43a 5a-113. Ed. One Plaza Business Center, Ofi 806",
        addressLocality: "Medellín",
        addressCountry: "CO",
        addressRegion: "El Poblado, sector milla de oro",
        postalCode: "050022"
      },
      sameAs: [
        "https://www.facebook.com/MagnetoEmpleos",
        "https://www.instagram.com/magnetoempleos/",
        "https://www.linkedin.com/company/magnetoempleos/",
        "https://www.magneto365.com/es"
      ]
    })
  };
};

export default organizationShcema;
