/* eslint-disable react/prop-types */
import style from "./postList.module.scss";
import CardPost from "./cardPost/cardPost.component";
import PostRow from "./postRow/postRow.component";
import BannerPost from "./bannerPost/bannerPost.component";
import { TWpGlobal } from "#types/wordPress";

type PostsListProps = {
  data: TWpGlobal[];
  img: boolean;
  type: string;
  quantity: number;
  omitFirst?: boolean;
  category?: number;
};

const PostsList: React.FC<PostsListProps> = ({ data, img, type, quantity, omitFirst, category }) => {
  return (
    <div
      className={`${"hoverFocus"} ${style.contentPost} ${
        type == "card"
          ? style.typeCard
          : type == "row"
          ? style.typeRow
          : type == "banner"
          ? style.typeBanner
          : style.type1
      }`}
    >
      {type == "card" ? (
        <CardPost data={data} img={img} quantity={quantity} omitFirst={omitFirst} category={category} />
      ) : type == "banner" ? (
        <BannerPost data={data} quantity={quantity} />
      ) : type == "row" ? (
        <PostRow data={data} quantity={quantity} />
      ) : null}
    </div>
  );
};

export default PostsList;
