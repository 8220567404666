/* eslint-disable react/prop-types */
import { ListIconLink } from "magneto365.ui";
import style from "../button.module.scss";
import {
  facebook,
  FacebookIcon,
  instagram,
  InstagramIcon,
  linkedin,
  LinkedinIcon,
  tiktok,
  TiktokIcon,
  whatsapp,
  WhatsappIcon,
  youtube,
  YoutubeIcon
} from "@constants";

type ButtonsSocialMediaProps = {
  text?: string;
};

type IListIcon = {
  title?: string;
  icon?: string | null;
  url?: string | null;
};

const ListIcon: IListIcon[] = [
  { title: "Magneto Facebook", icon: FacebookIcon, url: facebook },
  { title: "Magneto Instagram", icon: InstagramIcon, url: instagram },
  { title: "Magneto Linkedin", icon: LinkedinIcon, url: linkedin },
  { title: "Magneto Youtube", icon: YoutubeIcon, url: youtube },
  { title: "Magneto Tiktok", icon: TiktokIcon, url: tiktok },
  { title: "Magneto Whatsapp", icon: WhatsappIcon, url: whatsapp }
];

const ButtonsSocialMedia: React.FC<ButtonsSocialMediaProps> = ({ text }) => {
  return (
    <div className={style.buttonsSocialMediaMedia}>
      {text && <p>{text}</p>}
      <ListIconLink spacing={10} size={36} listIcon={ListIcon} />
    </div>
  );
};

export default ButtonsSocialMedia;
