/* eslint-disable react/prop-types */
import style from "./bodyRowPage.module.scss";

type BodyRowPageProps = {
  children: React.ReactNode;
};

const BodyRowPage: React.FC<BodyRowPageProps> = ({ children }) => {
  return <div className={style.bodyRowPage}>{children}</div>;
};

export default BodyRowPage;
