/* eslint-disable react/prop-types */
import Image from "next/image";
import { multimediaDomain } from "@constants";
import style from "./companies.module.scss";

type Data = {
  url: string;
  img: string;
  name: string;
};

type CompaniesImgProps = {
  data: Data;
  alt: string;
};

const CompaniesImg: React.FC<CompaniesImgProps> = ({ data, alt }) => {
  return (
    <Image
      src={`${multimediaDomain}${data.img}`}
      width={100}
      height={53}
      className={style.companyImg}
      alt={alt}
      title={alt}
    />
  );
};

export default CompaniesImg;
