import { TWpGlobal } from "@@types/wordPress";
import BodyRowPage from "@components/body/bodyRowPage.component";
import BtnCreateAccount from "@components/buttons/btnCreateAccount/component";
import ButtonCustom from "@components/buttons/btnCustom/component";
import ButtonsSocialMedia from "@components/buttons/btnSocialMedia/component";
import CustomVideo from "@components/customVideo/component";
import FrequentLinks from "@components/frequentLinks/component";
import PostsList from "@components/postsList/postList.component";
import ValidationCompanyLogos from "@components/validationCompanyLogos/component";
import { arrowIcon } from "@constants";
import dataJsonValidate from "@utils/dataJsonValidate/dataJsonValidate";
import parse from "html-react-parser";
import { TFunction, withTranslation } from "next-i18next";
import React, { Fragment } from "react";
import BlockAdvertising from "./blockAdvertising/component";
import BlockBannerBrowser from "./blockBannerBrowser/component";
import ImageText from "./blockImageText/component";
import BlockSlider from "./blockSlider/component";
import VerticalTabs from "./blockVerticalTabs/component";
import BusinessAndCorporate from "./businessCorporate/component";
import style from "./homeV2.module.scss";
import SectionContainer from "./sectionContainer";

type ContentHomeB2CProps = {
  data: TWpGlobal;
  dataSlider: TWpGlobal[];
  dataBlog: TWpGlobal[];
  t: TFunction;
};

const ContentHomeB2C: React.FC<ContentHomeB2CProps> = ({ data, dataSlider, dataBlog, t }) => {
  const text = t<string>("genericTexts:buttonTexts.createFreeAccount");
  const rest = data.acf;

  const { dataJson: tabs, dataValidate: tabsValidate } = dataJsonValidate({
    restInfo: rest.vertical_tabs,
    restData: rest.vertical_tabs.tabs
  });

  const { dataJson: videos, dataValidate: videosValidate } = dataJsonValidate({
    restInfo: rest.slider_videos,
    restData: rest.slider_videos.videos
  });

  return (
    <Fragment>
      <BlockBannerBrowser data={data} />
      <div className={style.generalLandingStyle}>
        <BodyRowPage>
          <ImageText data={data} />
          <ImageText data={data} pymes />

          <div className={style.titleLogosCompanies}>
            <ValidationCompanyLogos dataLogos={rest.company_logos} titleTag="h1" />
            <div className={style.containerSmall}>
              <BtnCreateAccount
                classIcon="las la-angle-right"
                color="purple"
                fontSize={16}
                iconType="classIcon"
                iconRight
                text={text}
              />
              {rest.cautionary_text && <small>{rest.cautionary_text}</small>}
            </div>
          </div>

          <BlockAdvertising rest={rest.advertising} />
        </BodyRowPage>

        <div className={style.containerSlider}>
          <BlockSlider dataSlider={dataSlider} />
        </div>

        <BodyRowPage>
          {tabsValidate && <VerticalTabs tabs={tabs} title={rest.vertical_tabs.title_tabs} />}

          {rest.id_youtube && (
            <div className={style.containerVideo}>
              {rest.vertical_tabs.title_tabs && <h2>{parse(rest.vertical_tabs.title_tabs)}</h2>}
              <CustomVideo videoId={rest.id_youtube} />
            </div>
          )}

          {rest.business_and_corporate && <BusinessAndCorporate rest={rest.business_and_corporate} />}

          <SectionContainer>
            {rest.blog_block_texts.title && <h2>{parse(rest.blog_block_texts.title)}</h2>}
            <PostsList type="row" data={dataBlog} img quantity={3} />
            <ButtonCustom href="/es/blog" txt="Ir al blog" customStyle={style.btn} icon={true} src={arrowIcon} />
            <ButtonsSocialMedia text={rest.blog_block_texts.text} />
          </SectionContainer>
        </BodyRowPage>

        {videosValidate && (
          <div className={style.containerSlider}>
            <SectionContainer type="Block">
              <h2>{parse(rest.slider_videos.title)}</h2>
              <BlockSlider type="sliderVideos" videos={videos} centerMode />
            </SectionContainer>
          </div>
        )}

        <BodyRowPage>
          <FrequentLinks />
        </BodyRowPage>
      </div>
    </Fragment>
  );
};

export default withTranslation()(ContentHomeB2C);
