import useScreenSizeListener from "@hooks/screenSizeListener/useScreenSizeListener";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment } from "react";
import style from "./bannerBrowser.module.scss";

type DataButton = {
  url: string;
  title: string;
  subtitle: string;
  text: string;
  icon: string;
};

type ButtonFilterProps = {
  data: DataButton;
};

const ButtonFilter: React.FC<ButtonFilterProps> = ({ data }) => {
  const middleScreen = useScreenSizeListener(1281);
  return (
    <Fragment>
      <Link href={data.url} className={style.buttonFilter}>
        <div className={style.containerInfoBtn}>
          {!middleScreen ? data.icon && <Image src={data.icon} alt={data.subtitle} width={25} height={25} /> : ""}
          <div>
            {data.title && <p className={style.btnFilterTitle}>{data.title}</p>}
            {data.subtitle && <p className={style.btnFilterSubTitle}>{data.subtitle}</p>}
          </div>
        </div>
        <i className="las la-angle-right"></i>
      </Link>
    </Fragment>
  );
};

export default ButtonFilter;
